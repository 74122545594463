import {
  Box,
  Container,
  Stack,
  StyleguideProvider,
  Text,
  SocialButton
} from "@verified/backoffice-styleguide";
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';

export function Footer(props) {
  return (
    <StyleguideProvider>
      <Box>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={{ base: "column", md: "row" }}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text>© 2023 Verified Global. All rights reserved</Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton label={"Twitter"} href={"#"}>
              <FaTwitter />
            </SocialButton>
            <SocialButton label={"YouTube"} href={"#"}>
              <FaYoutube />
            </SocialButton>
            <SocialButton label={"Instagram"} href={"#"}>
              <FaInstagram />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </StyleguideProvider>
  );
}
